/* eslint-disable */
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';

import { useSelector, useDispatch } from 'react-redux';
import {
  selectBookQueue,
  selectFilterLanguage,
  selectFilterLevel,
  selectFilterSource,
  selectFilterTopic,
  selectFilterType,
  selectFilterWords,
  selectFilters,
  selectSearch,
  selectIsLoading,
} from 'data/filter/selectors';
import { getFilteredBooks } from 'data/database';
import { actions as filterActions } from 'data/filter/slice';
import { InfiniteLoad } from 'components/organisms/CardList/InfiniteLoad';
import { useMediaQuery } from 'shared/hooks/useMediaQuery';
import { ThreeDots } from 'react-loader-spinner';
import { Wrapper, LoadingWrapper, NoResultsWrapper } from './wrappers';
import { getPWADisplayMode } from 'shared/functions/utils';

export const CardList = () => {
  const dispatch = useDispatch();
  const bookQueue = useSelector(selectBookQueue);
  const filters = useSelector(selectFilters);
  const language = useSelector(selectFilterLanguage);
  const source = useSelector(selectFilterSource);
  const type = useSelector(selectFilterType);
  const level = useSelector(selectFilterLevel);
  const words = useSelector(selectFilterWords);
  const topic = useSelector(selectFilterTopic);
  const search = useSelector(selectSearch);
  const isLoading = useSelector(selectIsLoading);
  

  // Initial index for bookQueue items to display
  const [index, setIndex] = useState(10);

  // Flag to track if more pages of books are available
  const [hasNextPage, setHasNextPage] = useState(true);

  // Number of items to fetch per batch
  const batchSize = 10;

  // Responsive queries to handle different screen sizes
  const isTablet = useMediaQuery('(max-width: 1370px)');
  const isMobile = useMediaQuery('(max-width: 768px)');

  // Check if the app is running in browser mode
  const browser = getPWADisplayMode() === 'browser';

  // Reset the index to 0 whenever the filters change
  useEffect(() => {
    // Reset the index only for filters
    setIndex(10);
    setHasNextPage(true);
  }, [language, level, source, topic, type, words, search]);

  // Helper function to calculate the height of the book pane dynamically
  const getHeight = useCallback(() => {
    if (!isMobile && !isTablet) return window.innerHeight;
    return window.innerHeight + 8;
  }, [isMobile, isTablet]);

  // Helper function to calculate the width of the book pane dynamically
  const getWidth = useCallback(() => {
    if (isMobile) {
      return window.innerWidth - 16;
    }
    if (isTablet) {
      return window.innerWidth - 328;
    }
    return window.innerWidth - 415;
  }, [isTablet, isMobile]);

  // State variables for the height and width of the book pane
  const [bookPaneHeight, setBookPaneHeight] = useState(getHeight());
  const [bookPaneWidth, setBookPaneWidth] = useState(
    isMobile ? window.innerWidth : getWidth(),
  );

  // Update the book pane dimensions on window resize
  window.addEventListener('resize', () => {
    setBookPaneHeight(getHeight());
    setBookPaneWidth(getWidth());
    return null;
  });

  // Initial setup to calculate the book pane dimensions
  useLayoutEffect(() => {
    setBookPaneHeight(getHeight());
    setBookPaneWidth(getWidth());
  }, [getHeight, getWidth]);

  // Function to load the next page of books
  async function loadNextPage() {
    console.log("IN LOAD NEXT PAGE!!!");
    dispatch(filterActions.setLoading(true));
    const newBooks = await getFilteredBooks(filters, index, batchSize, browser);
    dispatch(filterActions.setLoading(false));
    console.log("OUT LOAD NEXT PAGE");
    // Update the bookQueue with the new books appended
    const updatedBooks = index === 0 ? newBooks : [...bookQueue, ...newBooks];
    dispatch(filterActions.setBookQueue(updatedBooks));

    if (newBooks) {
      // If this update was for the full batch size, then there should be another page available
      setHasNextPage(newBooks.length === batchSize);
      setIndex(index + batchSize);
    }
  }

  // Render the component
  return (
    <Wrapper>
      {bookQueue?.length ? (
        // Render InfiniteLoad if there are books in the queue
        <InfiniteLoad
          {...{
            bookPaneHeight,
            bookPaneWidth,
            hasNextPage,
            isTablet,
            loadNextPage,
          }}
        />
      ) : isLoading ? (
        // Show a loading spinner while data is being fetched
        <LoadingWrapper>
          <ThreeDots width="100" color="#002664" ariaLabel="loading" visible />
        </LoadingWrapper>
      ) : (
        // Display "No results" message when bookQueue is empty and not loading
        <NoResultsWrapper>No results</NoResultsWrapper>
      )}
    </Wrapper>
  );
};
