import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  bookQueue: [],
  isLoading: true,
  modal: '',
  language: [],
  source: [],
  type: [],
  level: [],
  total_words: null,
  topic: [],
  search: '',
  library: [],
  selectAll: false,
};

const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload; // Set loading to true/false
    },
    applyModal(state, action) {
      state.modal = action.payload;
    },
    // State update actions
    setLanguage(state, action) {
      if (state.language.includes(action.payload)) {
        state.language = [...state.language.filter(i => i !== action.payload)];
      } else {
        state.language = [...state.language, action.payload];
      }
    },
    setSource(state, action) {
      if (state.source.includes(action.payload)) {
        state.source = [...state.source.filter(i => i !== action.payload)];
      } else {
        state.source = [...state.source, action.payload];
      }
    },
    setType(state, action) {
      if (state.type.includes(action.payload)) {
        state.type = [...state.type.filter(i => i !== action.payload)];
      } else {
        state.type = [...state.type, action.payload];
      }
    },
    setLevel(state, action) {
      if (state.level.includes(action.payload)) {
        state.level = [...state.level.filter(i => i !== action.payload)];
      } else {
        state.level = [...state.level, action.payload];
      }
    },
    setWords(state, action) {
      state.total_words = action.payload;
    },
    setTopic(state, action) {
      if (state.topic.includes(action.payload)) {
        state.topic = [...state.topic.filter(i => i !== action.payload)];
      } else {
        state.topic = [...state.topic, action.payload];
      }
    },
    setSearch(state, action) {
      state.search = action.payload;
    },
    setSelectAll(state, action) {
      state.selectAll = action.payload;
    },
    setLibrary(state, action) {
      state.library = action.payload;
      state.bookQueue = action.payload;
    },
    clear(state, action) {
      state.language = [];
      state.type = [];
      state.source = [];
      state.level = [];
      state.total_words = null;
      state.topic = [];
      state.search = '';
    },

    // Trigger the saga when the user clicks "Apply Filters"
    applyFilters: state => state, // This action triggers the saga

    fetchBooksStart(state) {
      // Possibly set some loading state
    },
    setBookQueue(state, action) {
      state.bookQueue = action.payload;
    },

    // Reset store
    resetStore: () => ({
      ...initialState,
    }),
  },
});

export const { 
  actions, 
  reducer, 
  name: sliceKey 
} = filterSlice;
