import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper, ImageWrapper, ColumnWrapper, ColColWrapper } from './wrappers';

const Row = ({
  color,
  imageBg,
  imageLeft,
  imageRight,
  imagePadding,
  imageMargin,
  maxWidth,
  imageHeight,
  fixBottom,
  ...props
}) => (
  <Wrapper {...{ color, ...props }}>
    {!!imageLeft && (
      <ImageWrapper
        style={{ backgroundImage: `url(${imageLeft})` }}
        {...{ imagePadding, imageBg, imageMargin, maxWidth }}
      />
    )}
    <ColColWrapper>
      <ColumnWrapper {...props}>{props.children}</ColumnWrapper>
    </ColColWrapper>
    {!!imageRight && (
      <ImageWrapper
        style={{ backgroundImage: `url(${imageRight})` }}
        {...{ imagePadding, imageBg, imageMargin, maxWidth, imageHeight, fixBottom }}
      />
    )}
  </Wrapper>
);

Row.defaultProps = {
  children: null,
  color: null,
  imageLeft: null,
  imageRight: null,
  imagePadding: 0,
  imageBg: false,
  maxWidth: null,
  imageMargin: false,
  imageHeight: null,
  fixBottom: false,
};

Row.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  imageLeft: PropTypes.string,
  imageRight: PropTypes.string,
  imagePadding: PropTypes.number,
  imageBg: PropTypes.bool,
  maxWidth: PropTypes.string,
  imageMargin: PropTypes.bool,
  imageHeight: PropTypes.string,
  fixBottom: PropTypes.bool,
};

export default Row;
