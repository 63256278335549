import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.containers.Nav.about',
    defaultMessage: 'About Us',
  },
  who: {
    id: 'app.containers.AboutUs.header',
    defaultMessage: 'Who We Are',
  },
  director: {
    id: 'app.containers.AboutUs.director',
    defaultMessage: `Director`,
  },
  cofounderF: {
    id: 'app.containers.AboutUs.cofounder-f',
    defaultMessage: `Co-founder`,
  },
  cofounderM: {
    id: 'app.containers.AboutUs.cofounder-m',
    defaultMessage: `Co-founder`,
  },
  backend: {
    id: 'app.containers.AboutUs.backend',
    defaultMessage: `Back-End Architect`,
  },
  frontend: {
    id: 'app.containers.AboutUs.frontend',
    defaultMessage: `Front-End Architect`,
  },
  gallery: {
    id: 'app.containers.AboutUs.gallery',
    defaultMessage: 'Gallery',
  },
  news: {
    id: 'app.containers.AboutUs.news',
    defaultMessage: 'Latest News',
  },
  newsAP: {
    id: 'app.containers.AboutUs.news-ap',
    defaultMessage:
      'Associated Press: South Windsor couple bringing literacy to Haitian children',
  },
  chantal: {
    id: 'app.containers.AboutUs.chantal',
    defaultMessage: `Chantal is the director of The Reading Room in Haiti and is responsible for managing libraries at three
    elementary schools, with a fourth library under development. She is the co-founder and principal of Ecole
     Préscolaire et Fondamentale (Le Foyer Elementary School) in Croix-des-Bouquets, Haiti. She is also the
     pedagogical consultant for Ecole Agape elementary school in Lillavois, Haiti and Institution Mixte Baptiste de la
     Vérité in Cuvier, Haiti.
     As co-founder of the Team Building Masters Haiti group, she presents communication workshops in schools
     and universities in Haiti and elsewhere. She earned a bachelor's degree in education in Haiti and a bachelor's
     degree in nursing from the Université de Sherbrooke in Quebec. She has also attended library sciences
     training in Haiti.
     She is married, the mother of three boys and has five grand-children. Her motto is: “Dare-Act-Influence”.`,
  },
  elizabeth: {
    id: 'app.containers.AboutUs.elizabeth',
    defaultMessage: `Elizabeth is a co-founder of The Reading Room and has worked with Haitian schools since 1996. She is
    certified as a literacy specialist (grades K-12), an elementary/secondary classroom teacher (grades pre-K-3;
     grades 4-8) and as a teacher of English to speakers of other languages. She has a master's degree in
     education and 6th year certificate in curriculum and instruction from The Neag School of Education at the
     University of Connecticut.
     She is a graduate of the Florida International University Summer Haitian Creole Language Program. She is
     also a graduate of Teachers College Reading & Writing Workshop Institutes at Columbia University: Literacy
     Coaching and Whole School Writing Reform. She is currently employed as a reading teacher at an elementary
     school in Connecticut. Elizabeth and her husband Paul have two adult children.`,
  },
  paul: {
    id: 'app.containers.AboutUs.paul',
    defaultMessage: `Paul is a co-founder of The Reading Room and has worked with Haitian schools since 1991. He has a leading
    role in the development of The Reading Room digital library app, as well as the flash library concept. He has a
    bachelor's degree in business and master's degree in economics from the University of Connecticut. In 2022
    he retired, after a 38-year career in business and technology, to focus on The Reading Room.`,
  },
  michael: {
    id: 'app.containers.AboutUs.michael',
    defaultMessage: `Michael is the son of Elizabeth and Paul and has been contributing to The Reading Room for most of his life,
    from assembling books for flash libraries to making trips to Haiti with his parents. He is now applying his professional
    skillset as a software engineer to build, scale, and maintain the backend of The Reading Room app to help get as many
    digital books into the hands of as many children as possible.`,
  },
  kyle: {
    id: 'app.containers.AboutUs.kyle',
    defaultMessage: `Kyle is the godson of Elizabeth and Paul and works professionally as a software architect. He is responsible for
    designing, building, and future-proofing the frontend of The Reading Room website and app to make sure they're beautiful, accessible,
    and intuitive for children and adults alike.`,
  },
});
