/* eslint-disable */
import { openDB } from 'idb';
import { lookupFilter } from './filter/constants';

const DATABASE_NAME = 'ReadingRoom';
const DATABASE_VERSION = 2;
const DATA_STORE_NAME = 'bookDataStore';
const PDF_STORE_NAME = 'bookPDFStore';
const FOLDERLIST_STORE = "folderlistStore"

async function createDatabase() {
  const database = await openDB(DATABASE_NAME, DATABASE_VERSION, {
    upgrade(db) {
      // Create a store if it doesn't exist
      if (!db.objectStoreNames.contains(DATA_STORE_NAME)) {
        db.createObjectStore(DATA_STORE_NAME, { autoIncrement: true });
      }
      if (!db.objectStoreNames.contains(PDF_STORE_NAME)) {
        db.createObjectStore(PDF_STORE_NAME, { autoIncrement: true });
      }
      if (!db.objectStoreNames.contains(FOLDERLIST_STORE)) {
        db.createObjectStore(FOLDERLIST_STORE, { autoIncrement: true });
      }
    },
  });
  return database;
}

async function storeData(data) {
  console.log("TRYINA STORE DATA!");
  // Store JSON data in the bookDataStore and PDF files in the bookPDFStore for memory purposes
  const db = await createDatabase();
  const tx = db.transaction([DATA_STORE_NAME, PDF_STORE_NAME], 'readwrite');
  const dataStore = tx.objectStore(DATA_STORE_NAME);
  const pdfStore = tx.objectStore(PDF_STORE_NAME);

  console.log("STILL TRYINA STORE DATA!");

  for (let i = 0; i < data.json.length; i++) {
    const json = JSON.parse(data.json[i]);
    const dataItem = {
      png: data.images[i],
      ...json,
    };
    const pdfItem = data.pdfs[i];
    dataStore.put(dataItem, dataItem.code);
    pdfStore.put(pdfItem, dataItem.code);
  }
  console.log("TRYINA STORE DATA again!");
  await tx.done;
  console.log("Ddone");
}

async function storeFolderlist(folderList) {
  // Store JSON data in the bookDataStore and PDF files in the bookPDFStore for memory purposes
  const db = await createDatabase();
  const tx = db.transaction(FOLDERLIST_STORE, 'readwrite');
  const dataStore = tx.objectStore(FOLDERLIST_STORE);
  dataStore.put(folderList, "folderList");
  await tx.done;
  return folderList;
}

async function getFolderList() {
  // Store JSON data in the bookDataStore and PDF files in the bookPDFStore for memory purposes
  const db = await createDatabase();
  const tx = db.transaction(FOLDERLIST_STORE, 'readwrite');
  const dataStore = tx.objectStore(FOLDERLIST_STORE);
  const folderList = dataStore.get("folderList");
  await tx.done;
  return folderList;
}

async function getLibrary() {
  const db = await createDatabase();
  const tx = db.transaction(DATA_STORE_NAME, 'readonly');
  const store = tx.objectStore(DATA_STORE_NAME);
  const allData = await store.getAll();
  await tx.done;
  return allData;
}

function checkBookFilters(book, filters) {
  const languageMatch =
    !filters.language[0] ||
    filters.language.includes(lookupFilter[book.language]);
  const sourceMatch =
    !filters.source[0] || filters.source.includes(lookupFilter[book.source]);
  const typeMatch =
    !filters.type[0] || filters.type.includes(lookupFilter[book.type]);
  const levelMatch =
    !filters.level[0] || filters.level.includes(lookupFilter[book.level]);
  const totalWordsMatch =
    !filters.total_words ||
    book.total_words <= filters.total_words ||
    (filters.total_words === '2500+' && book.total_words >= 2500);
  const topicMatch =
    !filters.topic[0] ||
    filters.topic.some(t =>
      [lookupFilter[book['topic 1']], lookupFilter[book['topic 2']]].includes(
        t,
      ),
    );
  const searchMatch =
    !filters.search ||
    book.title.toLowerCase().includes(filters.search.toLowerCase()) ||
    book.author.toLowerCase().includes(filters.search.toLowerCase());

  return (
    languageMatch &&
    sourceMatch &&
    typeMatch &&
    levelMatch &&
    totalWordsMatch &&
    topicMatch &&
    searchMatch
  );
}

async function getBook(id) {
  // Lookup JSON data from the bookDataStore and the PDF file from the bookPDFStore
  const db = await createDatabase();
  const tx = db.transaction([DATA_STORE_NAME, PDF_STORE_NAME], 'readwrite');
  const dataStore = tx.objectStore(DATA_STORE_NAME);
  const pdfStore = tx.objectStore(PDF_STORE_NAME);
  const bookData = await dataStore.get(id);
  const bookPDF = await pdfStore.get(id);
  await tx.done;
  return { ...bookData, id, pdf: bookPDF };
}

async function getLibraryCount() {
  const db = await createDatabase();
  const tx = db.transaction(DATA_STORE_NAME, 'readonly');
  const store = tx.objectStore(DATA_STORE_NAME);

  const countRequest = await store.count();
  return countRequest;
}

async function getFilteredBooks(filters, index, batchSize, browser) {
  if(browser){
    console.log("+++++++++++++++++++++++++++++++");
  console.log(index);
  console.log(batchSize);
  //console.log(filters);
  console.log("+++++++++++++++++++++++++++++++");
  try {
    const response = await fetch('https://S420L.club/api/get_books', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({filters: filters, index: index, batchSize: batchSize})
    });

    if (!response.ok) {
      throw new Error('Failed to fetch data');
    }

    const bookQueue = await response.json();
    console.log("BOOK QUEUE!!");
    console.log("______________________________")
    console.log(bookQueue);
    console.log("______________________________")
    return bookQueue;
  } catch (error) {
    console.error(error);
    throw error;
  }
} else {
  const db = await createDatabase();
  const tx = db.transaction(DATA_STORE_NAME, 'readonly');
  const store = tx.objectStore(DATA_STORE_NAME);
  let allBooks = await store.getAll();
  await tx.done;

  // console.log('getting filtered books', filters, index, batchSize);
  let filteredBooks = allBooks.filter(book => checkBookFilters(book, filters));
  // Make sure allBooks gets cleared from memory
  allBooks = [];

  if (index !== undefined && batchSize !== undefined)
    filteredBooks = filteredBooks.slice(index, index + batchSize);
  // console.log('result', filteredBooks);
  return filteredBooks;
}
}

export {
  createDatabase,
  getLibrary,
  getBook,
  storeData,
  getLibraryCount,
  getFilteredBooks,
  storeFolderlist,
  getFolderList,
};
