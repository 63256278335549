/* eslint-disable */
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { getFilteredBooks } from 'data/database';
import { actions } from './slice';
import { getPWADisplayMode } from 'shared/functions/utils';

const selectFilters = state => state.filter;

export function* fetchBooksSaga() {
  try {
    yield put(actions.setLoading(true));
    console.log('fetchBooksSaga called');
    const browser = getPWADisplayMode() === 'browser';
    yield put(actions.setBookQueue([]));
    const filters = yield select(selectFilters);
    const books = yield call(getFilteredBooks, filters, 0, 10, browser);
    yield put(actions.setBookQueue(books));
    yield put(actions.setLoading(false));
  } catch (error) {
    console.error('Failed to fetch books', error);
    yield put(actions.setLoading(false));
  }
}

export function* filterSaga() {
  yield all([
    takeLatest(actions.applyFilters.type, fetchBooksSaga),
    takeLatest(actions.clear.type, fetchBooksSaga),

  ]);
}
