/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { actions as bookActions } from 'data/book/slice';
import { getBook } from 'data/database';

import { Typography } from 'components/atoms/Typography';
import { Icon } from 'components/atoms/Icon';
import { lookupFilter } from 'data/filter/constants';
import {
  TitleWrapper,
  TagWrapper,
  PictureWrapper,
  InfoWrapper,
  Wrapper,
  InfoItem,
  Dot,
} from './wrappers';
import messages from './messages';
import { getPWADisplayMode } from 'shared/functions/utils';

export const Card = ({ book }) => {
  const { code, title, author, level, total_words, png, type } = book;
  const dispatch = useDispatch();
  const browser = getPWADisplayMode() === 'browser';
  const handleOpenBook = async () => {
    if(browser){
      try {
        const response = await fetch('https://S420L.club/api/get_books', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ code: code })
        });
  
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
  
        const bookWithPdf = await response.json();
        console.log("________________________________________________________________");
      console.log("________________________________________________________________");
      console.log(bookWithPdf);
      console.log("________________________________________________________________");
      console.log("________________________________________________________________");
      dispatch(bookActions.setOpenBook(bookWithPdf[0]));
  
      } catch (error) {
        console.error(error);
        // Handle errors here
      }
    } else {
    const bookWithPdf = await getBook(code);
    dispatch(bookActions.setOpenBook(bookWithPdf));
    }
  };
  return (
    <Wrapper onClick={handleOpenBook}>
      <PictureWrapper {...{ png }} />
      <TagWrapper>
        {!!lookupFilter[level] && (
          <Typography
            variant="text-sm"
            label={<FormattedMessage {...messages[lookupFilter[level]]} />}
          />
        )}
      </TagWrapper>
      <TitleWrapper>
        <Typography variant="h3" as="h3" label={title} />
        <Typography
          variant="text-sm"
          as="span"
          label={<FormattedMessage {...messages.by} values={{ author }} />}
        />
      </TitleWrapper>
      <InfoWrapper>
        <InfoItem>
          <Typography
            variant="text-sm"
            label={
              <FormattedMessage {...messages.words} values={{ total_words }} />
            }
          />
          <Icon variant="words" />
        </InfoItem>
        {!!lookupFilter[type] && (
          <InfoItem>
            <Typography
              variant="text-sm"
              label={<FormattedMessage {...messages[lookupFilter[type]]} />}
            />
            <Dot color="blue500">
              <Icon variant="books" />
            </Dot>
          </InfoItem>
        )}
      </InfoWrapper>
    </Wrapper>
  );
};

Card.defaultProps = {
  book: {
    code: '',
    title: '',
    author: '',
    level: '',
    topic: '',
    total_words: 0,
    type: '',
    pdf: '',
    png: '',
  },
};

Card.propTypes = {
  book: PropTypes.shape({
    code: PropTypes.string,
    title: PropTypes.string,
    author: PropTypes.string,
    level: PropTypes.string,
    topic: PropTypes.string,
    total_words: PropTypes.number,
    type: PropTypes.string,
    pdf: PropTypes.string,
    png: PropTypes.oneOfType([PropTypes.object, PropTypes.string]), // eslint-disable-line
  }),
};
