/* eslint-disable */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as filterActions } from 'data/filter/slice';
import { selectSearch } from 'data/filter/selectors';

import { Icon } from 'components/atoms/Icon';
import { Bar, Search, IconWrapper } from './wrappers';

export const SearchBar = ({setIsMobileMenuOpen}) => {
  const search = useSelector(selectSearch);
  const dispatch = useDispatch();

  const handleSearchSubmit = () => {
    setIsMobileMenuOpen(false);
    dispatch(filterActions.applyFilters()); // Trigger API call
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent unintended behavior
      handleSearchSubmit(); // Call the API
    }
  };

  return (
    <Bar>
      <Search
        type="search"
        value={search}
        onChange={e => dispatch(filterActions.setSearch(e.target.value))}
        onKeyDown={handleKeyDown} // Listen for Enter key
        placeholder="Search by title or author"
      />
        <IconWrapper onClick={handleSearchSubmit}>
        <Icon variant="search" />
      </IconWrapper>
    </Bar>
  );
};
